.bu-login-wrap
    position: fixed
    top: calc(50% - 107px)
    left: calc(50% - 107px)
    height: 214px
    width: 214px
    display: flex
    flex-direction: column
    flex-wrap: wrap
    gap: 4px
    padding: 8px
    justify-content: center
    align-items: center

.bu-logo-img
    width: 84px
    height: 72px

.bu-title
    font-size: 22px

.bu-sub-title
    font-size: 16px

.bu-menu-upgrade-btn
    width: 120px
    height: 40px
    border-radius: 6px
    background: linear-gradient(90.14deg, #7D68FF -6.93%, #8899FA 161.2%)
    font-family: 'Poppins', sans-serif
    font-size: 14px
    font-weight: 500
    border: none
    align-items: center
    display: flex
    padding: 4px 21px
    margin-top: 8px

.bu-cross-icon
    position: fixed
    cursor: pointer
    right: 8px
    top: 8px