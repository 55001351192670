@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 300;
  src: url('./Assets//fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  src: url('./Assets//fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  src: url('./Assets//fonts/Poppins-SemiBold.ttf') format('truetype');
}

:root {
  --common-color: #000000;
  --main-bg-color: #EDEDED;
  --header-bg-color: #D8D8D8;
  --contrast-color: #e4e3e3;
  --border-color: #000000;
  --yellow: #F1F894;
  --yellow-on-black: #ECFB70;
  --input-color: #FFFFFF;
  --text-color: #2E2E2E;
  --text-secondary: #555555;
  --text-dark: #000000;
  --gray-color: #6A6A6A;
  --light-gray-color: #494949;
  --divider: #565656;
  color-scheme: light;
}

html.dark {
  --common-color: #FFFFFF;
  --main-bg-color: #191919;
  --header-bg-color: #121212;
  --contrast-color: #2A2A2A;
  --yellow-on-black: #212121;
  --input-color: #2A2A2A;
  --border-color: #F1F894;
  --text-color: #8C8C8C;
  --text-secondary: #C0C0C0;
  --text-dark: #F1F894;
  --gray-color: #8A8A8A;
  --light-gray-color: #BBBBBB;
  --divider: #565656;
  color-scheme: dark;
}

body{
  background-image: url('./Assets/repat.png');
  background-position: center;
  background-size: cover;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}