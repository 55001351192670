.footer-container{
    // padding: 10px;
    .footer-text{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        color: #8C8C8C;
        padding: 10px;
    }
}