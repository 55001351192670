.build-up-transcript-container {
    // max-width: 400px !important;
    margin: 0 !important;
    padding: 0 !important;
    overflow: auto !important;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 0;

    .build-up-transcript-chat {
        overflow-y: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        .build-up-loader {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        .build-up-bot-chat {
            max-width: 404px;
            display: flex;
            justify-content: start;
            padding: 8px;
            gap: 10px;
            border-radius: 10px;
            font-family: 'Poppins';
            font-size: 15px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            min-height: 44px;
            align-items: center;
            // margin: 8px;
        }

        .build-up-user-chat {
            max-width: 404px;
            display: flex;
            justify-content: end;
            padding: 8px;
            gap: 10px;
            border-radius: 11px 3px 16px 11px;
            background: linear-gradient(to right, #7D68FF, #8AA3F9);
            font-family: 'Poppins';
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            text-align: left;
            color: #FFFFFF;
            margin: 15px 0px;
            min-height: 44px;
            align-items: center;
        }

        .build-up-icons-btn-light {
            color: #474747;

            svg {
                cursor: pointer;
            }
        }

        .build-up-icons-btn-dark {
            color: #B8B8B8;

            svg {
                cursor: pointer;
            }
        }
    }


}