html body[data-scroll-locked] {
  overflow: auto !important;
  margin-right: 0px !important;
}

p,
h1,
h2,
h3,
h4,
h5,
span {
  font-family: "Poppins", sans-serif;
}

.build-up-layout-container {
  border-radius: 20px;
  border-width: 2px;
  /* border-image: linear-gradient(to bottom, rgb(237, 28, 35) 0%, rgb(0, 101, 163) 100%) 1; */
  background-color: var(--main-bg-color);
  position: fixed;
  top: 0px;
  right: 0px;
  min-height: 96svh !important;
  width: 530px;
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* don't remove */
  display: none;
  flex-direction: column;
  border: rgb(237, 28, 35) 2px solid;
  margin-top: 14px;
  z-index: 9999;
  animation: move;
  animation-duration: 0.6s;
}

@keyframes move {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

.build-up-layout-container.show {
  animation: slideIn 0.5s forwards;
}

.build-up-layout-container.hide {
  animation: slideOut 0.5s forwards;
}

.build-up-main-btn.show {
  display: none;
}

.chat-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 150px;
  height: auto;
  cursor: pointer;
  display: flex;
  z-index: 9998;
}

.chat-button-hover{
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 150px;
  height: auto;
  cursor: pointer;
  display: flex;
  z-index: 9998;
  /* display: none; */
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.merlinExt-custom-scrollbar {
  --scrollbar-track: #e4e4e7;
  --scrollbar-thumb: #a1a1aa;
  --scrollbar-width: 6px;

  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
}

.merlinExt-custom-scrollbar::-webkit-scrollbar {
  width: var(--scrollbar-width);
}

.merlinExt-custom-scrollbar::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
  border-radius: 4px;
}

.merlinExt-custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 4px;
}

.merlinExt-custom-scrollbar::-webkit-scrollbar-corner {
  background-color: var(--scrollbar-track);
}

.App-header {
  /* background-color: #212020; */
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    font-family: "Poppins";
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;

    /* --border: 214.3 31.8% 91.4%; */
    --input: 214.3 31.8% 91.4%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }

  .dark {
    
    --foreground: 213 31% 91%;

    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;

    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;

    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;

    /* --border: 216 34% 17%; */
    --input: 216 34% 17%;

    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;

    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;

    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;

    --ring: 216 34% 17%;

    --radius: 0.5rem;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}