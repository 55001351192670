.build-up-header-card {
  padding: 16px 26px;
  background-color: var(--header-bg-color);
  border-radius: 20px 20px 20px 20px;

  .bu-topbar {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 32px;

    .build-up-header-logo {
      width: auto;
      height: 40px;
      cursor: pointer;
    }

    .bu-actions {
      display: flex;
      align-items: center;
      margin-left: auto;

      .icon-btn {
        width: 38px;
        height: 42px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
        color: rgb(237, 28, 35);
        cursor: pointer;
        button{
          background-color: transparent;
          cursor: pointer;
        }

        &:hover {
          background-color: var(--contrast-color);
        }
      }
    }
  }

  .build-up-header-selecters {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 100%;
    button{
      font-weight: 500;
      span{
        font-weight: 500 !important;
        font-size: 16px !important;
        line-height: 1 !important;
        margin-top: 2px;
        white-space: nowrap !important;
      }
      box-shadow: none !important;
      outline: none;
      background-color: rgb(237, 28, 35, 0.3);
      border-color: rgb(237, 28, 35, 0.3);
    }
  }
}

.build-up-dropdown-selector {
  margin-top: 0px !important;
  position: relative !important;
  z-index: 1000000 !important;
  color: #C0C0C0;
}

.menubar-item-focused:focus {
  background-color: #121212;
  color: #C0C0C0;
  border-radius: 5px;
}

.menubar-item-focused-clear:focus {
  background-color: unset !important;
}